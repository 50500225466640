<template>
  <div class="container-9 w-container">
    <div class="rich-text-block-3 w-richtext" style='text-align: justify;'>
      <h1>
        <strong>Bases del Sorteo "Encuesta Lanzamiento HelloBB"</strong>
      </h1>

      <h3>Primero.- Objeto</h3>
      <p>Con el objetivo de tener conocimiento de las opiniones de los usuarios relativas a su lanzamiento, HelloBB promueve el sorteo denominado “Encuesta Lanzamiento HelloBB”, (en adelante, el “Sorteo”), el cual se regirá por las presentes bases legales (las “Bases Legales”).</p>
      <h3>Segunda.- Período de la promoción</h3>
      <p>El plazo durante el cual se podrá participar en el Sorteo dará comienzo el 01 de septiembre 2020 a las 08h00 horas y finalizará el 31 de diciembre 2020 a las 23:59 (ambas hora peninsular Española).</p>
      <h3>Tercera.- Ámbito Territorial de la promoción</h3>
      <p>La presente promoción será válida en todo el territorio de España.</p>
      <h3>Cuarta.- Naturaleza de la promoción</h3>
      <p>La participación en el presente Sorteo es gratuita, y se articula a través de la mera cumplimentación de un cuestionario de preguntas por parte del usuario, su correspondiente aceptación de la presente base legal del Sorteo y política de privacidad de datos, y por último la aceptación del envío del cuestionario cumplimentado a HelloBB.</p>
      <h3>Quinta.- Condiciones de participación</h3>
      <p>Podrán participar en el Sorteo todas aquellas personas físicas que hayan cumplimentado el cuestionario, y siempre que cumplan los requisitos establecidos en la Cláusula Séptima.</p>
      <h3>Sexta.- Limitación de participación</h3>
      <p>Quedan excluidos de la participación del presente Sorteo los empleados de HelloBB. Solo se admite una participación por persona, el resto de participaciones quedarán excluidas.</p>
      <h3>Séptima.- Mecánica del Concurso y notificación de los Premios</h3>
      <p>7.1 Para participar en el Sorteo y poder optar al Premio indicado en la cláusula Octava siguiente, es necesario: (i) ser persona física y mayor de edad; (ii) que la persona conteste el cuestionario de HelloBB ; (iii) acepte la presente base legal del Sorteo y política de privacidad de datos; (iv)  y por último la aceptación al envío del cuestionario cumplimentado a HelloBB.</p>
      
      <p>7.2 No tomarán parte en el Sorteo aquellas personas que incumplan alguna de las cláusulas de las presentes bases del Sorteo.  De igual modo, no tomarán parte en el Sorteo aquellos cuestionarios que a criterio de HelloBB, en su condición de entidad organizadora del Sorteo, pueden tener una naturaleza/ contenido inapropiado, ofensivo, ilícito.</p>

      <p>7.3 El Sorteo se realizará el día siguiente a la fecha de finalización indicada en la cláusula Segunda anterior, a través de la aplicación generadora de sorteos <a href='https://www.sortea2.com/sorteos'>Sortea2</a>. HelloBB se reserva el derecho de modificar la fecha de la realización del Sorteo cuando concurran causas suficientes que así lo justifiquen.</p>

      <p>7.4 Tras la realización del Sorteo, las personas premiadas serán contactadas vía mail, para informarles del premio ganado, así como solicitarles la aceptación del mismo. Si una persona premiada no comunica a HelloBB su aceptación en el plazo de 7 días naturales desde la fecha de notificación o bien no puede ser contactado (por causas ajenas no imputables a HelloBB), se procederá a contactar a uno de los cinco suplentes dispuestos por orden de extracción para la realización del mismo funcionamiento anterior.  Una vez finalizada la lista de suplentes, sin poder entregar el premio dispuesto en el Sorteo, se volverá a realizar el sorteo en el plazo máximo de 7 días naturales, del mismo modo dispuesto en la presente cláusula Séptima.</p>
      <h3>Octava.- Premios</h3>
      <p>8.1 Se otorgan una (1) tarjeta regalo de Amazon, valoradas por un importe de cincuenta (50) euros.</p>
      <p>8.2 Los premios quedarán sujetos a las siguientes condiciones:</p>
      <ul>
        <li>Los premios no podrán ser objeto de cambio, alteración o compensación alguna a petición de la persona premiada.</li>
        <li>La persona premiada podrá renunciar al premio obtenido, pero en ningún caso se ofrecerá un premio alternativo, ni dicha renuncia dará derecho de indemnización o compensación.</li>
      </ul>
      <p>En el caso de que HelloBB, detecte cualquier anomalía o tenga sospechas de que algún participante en el Sorteo está imposibilitando el normal desarrollo del Sorteo, perjudicando a los fines del mismo, o llevando a cabo cualesquiera actos fraudulentos que atenten contra su desarrollo, HelloBB se reserva el derecho de dar de baja del sorteo al participante o participantes implicados, todo ello sin perjuicio de cualesquiera otras medidas legales que estime oportunas.</p>
      <h3>Décima.- Exoneración de responsabilidad</h3>
      <p>En el presente Sorteo, HelloBB queda exonerada de cualquier responsabilidad que pudiera derivarse de cualquier circunstancia imputable a terceros que pudiera afectar al desarrollo de la mecánica del Sorteo, y en especial a la entrega de los premios. En este mismo sentido, HelloBB no será en ningún caso responsable de cualquier daño, defecto, de cualquier naturaleza, directo, indirecto/ circunstanciales, ya sean inmediatos o diferidos, que pudieran aparecer con ocasión o como consecuencia del disfrute del Premio.</p>
      <h3>Undécima.- Reclamaciones</h3>
      <p>Cualesquiera reclamaciones que se originen por causa del presente Sorteo deberán presentarse dentro del plazo de un (1) mes siguiente a la fecha de celebración del mismo.</p>
      <h3>Duodécima.- Protección de Datos</h3>
      <p><strong>Información Básica sobre Protección de Datos</strong></p>
      <table class='tbstyle'>
        <tr>
          <th>Responsable</th>
          <th>HelloBB</th>
        </tr>
        <tr>
          <th>Finalidad</th>
          <th>Participación en el sorteo, y en su caso, comunicación y gestión de la entrega del premio, así como proporcionarles información, técnica, operativa o comercial sobre los productos y servicios de HelloBB.</th>
        </tr>
        <tr>
          <th>Datos</th>
          <th>Nombre, apellidos, correo electrónico.</th>
        </tr>
        <tr>
          <th>Legitimación</th>
          <th>Consentimiento del interesado.</th>
        </tr>
        <tr>
          <th>Destinatarios</th>
          <th>Los datos con fines comerciales pueden ser comunicados según lo dispuesto en nuestra política privacidad (ver información adicional).</th>
        </tr>
        <tr>
          <th>Derechos</th>
          <th>Acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional.</th>
        </tr>
        <tr>
          <th>Información adicional</th>
          <th>Puede consultar la información adicional y detallada sobre Protección de datos en el portal web http://www.hellobb.net/cookies.</th>
        </tr>
      </table>
      <h3>Decimotercera.- Autorización de utilización del nombre y cesión de derechos de imagen</h3>
      <p>Cada una de las personas premiadas autoriza a HelloBB a reproducir su nombre, apellidos e imagen, en cualquier actividad publicitaria o promocional en cualquier soporte o medio de comunicación relacionado con el presente Sorteo.  La autorización y la cesión de derechos contempladas en este documento no se encuentra vinculada a la vigencia de cualquier otro contrato o sorteo, con objeto distinto, suscrito o que pueda suscribirse entre la persona premiada y HelloBB. Asimismo, la autorización y cesión se realiza de forma gratuita y sin limitación geográfica, para la utilización de los medios técnicos conocidos en la actualidad y los que pudieran desarrollarse en el futuro.</p>

      <p>HelloBB le informa que tratará sus datos personales y su imagen, según las finalidades expuestas en este escrito. La base legal para el tratamiento de estos datos es su consentimiento. La persona premiada tiene derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a la retirada.</p>

      <p>En todo caso, usted puede ejercitar los derechos de acceso, rectificación, supresión, portabilidad, limitación del tratamiento u oposición, dirigiéndose por correo electrónico a hola@hellobb.net.</p>
      <h3>Decimocuarta.- Aceptación de las Bases Legales</h3>
      <p>La participación en el Sorteo supone la aceptación íntegra de las presente Bases Legales, así como la aceptación del criterio de HelloBB en las decisiones interpretativas que de las mismas efectúe.</p>
      <h3>Decimoquinta.- Modificaciones</h3>
      <p>HelloBB en el caso de existir causas de fuerza mayor ajenas a su control, se reserva el derecho de efectuar cualquier cambio, suspender, acortar o ampliar este Sorteo, comprometiéndose a dar a dichas modificaciones el mismo grado de publicidad que a las presentes Bases Legales, mediante correo electrónico o a través de la página web corporativa de HelloBB.</p>
      <h3>Decimosexta.- Ley aplicable y fuero</h3>
      <p>La presente promoción se regirá por el derecho español común vigente. Para toda controversia que pudiera surgir de la interpretación y aplicación de las presentes Bases Legales, tanto HelloBB como los participantes de este Sorteo, se someten expresamente a la jurisdicción de los Juzgados y Tribunales de Barcelona, sin perjuicio del fuero que por ley pudiera corresponder al consumidor.</p>
    </div>
    </div>


</template>

<script>
export default {
  name: "Bases",
  metaInfo() {
    return {
      title: "Bases concurso"
    };
  },

  data() {
    return {};
  },
  computed: {},

  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
  },

  // Client-side only
  mounted() {},

  methods: {}
};
</script>
<style scoped>
.tbstyle {
  text-align:left;
}
.tbstyle th {
  width: 50%;
  font-weight: normal;
  border:1px solid grey;
}
</style>